
//==================================================================
//
//                            Form Actions
//
//==================================================================

export const setCompanyType = (data) => {
    return {
        type: "SET_COMPANY_TYPE",
        data: data
    }
};

export const updateFormStandard = (data) => {
    return {
        type: "UPDATE_FORM_STANDARD",
        data: data
    }
}

export const updateFormSubsection = (subsection, data) => {
    return {
        type: "UPDATE_FORM_SUBSECTION",
        subsection: subsection,
        data: data
    }
}

//==================================================================
//
//                        Form Completion Actions
//
//==================================================================

/**
 * 
 * @param {String} updateType 
 * start
 * companyType
 * email
 */
export const updateStandardSection = (updateType) => {
    return {
        type: "UPDATE_STANDARD_SECTION",
        updateType: updateType
    }
}

/**
 * 
 * @param {String} updateType 
 * @param {String} updateSubsection 
 * climateTech
 *      companyType
 *      goals
 *      projectSpecs
 *      projectType
 * fund
 *      focus
 *      goals
 *      fundType
 * other
 *      companyType
 *      projectSpecs
 *      serviceInterests
 *      goals
 */
export const updateCustomizedSubsection = (updateType, updateSubsection) => {
    return {
        type: "UPDATE_CUSTOMIZED_SUBSECTION",
        updateType: updateType,
        updateSubsection: updateSubsection,
    }
}