import { Button, Heading, Flex, Text } from "@chakra-ui/react";
import styled from "styled-components";

export const FormContainer = styled(Flex).attrs((props) => ({
  flex: "5",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  p: "5"
}))``;

export const FormLabel = styled(Text).attrs((props) => ({
  fontSize: "22px",
  fontWeight: "semibold",
  mb: "1",
}))``;

export const FormHeading = styled(Heading).attrs((props) => ({
  fontSize: "48px",
  fontWeight: "normal",
  color: "blue.dark",
  mt: "8",
}))``;

export const TitleHeading = styled(Heading).attrs((props) => ({
  fontSize: { base: "48px", lg: "64px", xl: "80px" },
  fontWeight: "normal",
  color: "white",
}))``;

export const SectionHeading = styled(Heading).attrs((props) => ({
  fontSize: { base: "24px", md: "28px", lg: "30px" },
  fontWeight: "normal",
  color: "gray.dark",
  textAlign: { base: "left", md: "center" },
}))``;

export const ProfileHeading = styled(Heading).attrs((props) => ({
  fontSize: { base: "20px", md: "22px", lg: "25px" },
  fontWeight: "normal",
  color: "gray.dark",
  mb: 2,
}))``;

export const NameHeading = styled(Heading).attrs((props) => ({
  fontSize: { base: "30px", md: "40px", lg: "50px" },
  fontWeight: "normal",
  color: "gray.dark",
  textAlign: "center",
}))``;

export const SubHeading = styled(Text).attrs((props) => ({
  mb: "4",
  fontSize: "20px",
}))``;

export const SectionSubheading = styled(Text).attrs((props) => ({
  fontSize: { base: "16px", lg: "18px" },
  color: "gray.dark",
  textAlign: { base: "flex-start", md: "center" },
}))``;

export const ItemHeading = styled(Text).attrs((props) => ({
  fontSize: "30px",
  color: "gray.dark",
  pt: 4,
}))``;

export const ItemSubheading = styled(Text).attrs((props) => ({
  fontSize: "16px",
  color: "gray.dark",
  width: "300px",
  textAlign: "center",
  pt: 8
}))``;

export const ButtonNext = styled(Button).attrs((props) => ({
  backgroundColor: "blue.light",
  color: "white",
  borderRadius: "50px",
  width: "115px",
  type: "submit",
}))``;

export const ButtonPrevious = styled(Button).attrs((props) => ({
  backgroundColor: "gray.50",
  color: "gray.900",
  borderRadius: "50px",
  width: "115px",
}))``;

export const SidebarHeading = styled(Text).attrs((props) => ({
  fontSize: "22px",
  fontWeight: "semibold",
  mb: "10",
  color: "white",
}))``;
