import React, { useState } from "react";

// Components
import { Flex, Divider, Heading, Text } from "@chakra-ui/react";
import { Progress, Radio } from "antd";
import { FormContainer, FormHeading, SubHeading , ButtonNext, ButtonPrevious } from "../General/Styled";
import Sidebar from "../General/Sidebar";

// Styles and Icons
import { useSpring, animated } from "react-spring";
import COLORS from "../../colors";
import LogoContainer from "../General/LogoContainer";
import { ReactComponent as IconClimateTech } from "../../assets/iconClimateTech.svg";
import { ReactComponent as IconFund } from "../../assets/iconFund.svg";
import { ReactComponent as IconOther } from "../../assets/iconOther.svg";

//Router and Redux
import { Link, Redirect } from "react-router-dom";
import { setCompanyType, updateStandardSection } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const FormCompanyTypePage = (props) => {
  //Redux
  const companyType = useSelector((state) => state.form.companyType);
  //Checks to see if the previous page has been completed first
  const isViewable = useSelector(state => state.formCompletion.expertNeeds);
  const dispatch = useDispatch();

  //State values
  const [currentValue, setCurrentValue] = useState(companyType || null);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleClick = () => {
    if (currentValue === null) {
      //Errs out because the user has not filled out their company
      setErrorMessage(true);
    } else {
      //Dispatch companyType and completion state to redux
      dispatch(setCompanyType(currentValue));
      dispatch(updateStandardSection("companyType"));

      //Navigate to the next page
      props.history.push("/3");
    }
  };

  const handleValueChange = (val) => {
    //Changes the current selected value
    setCurrentValue(val);

    //Removes the error message if there is one
    if (errorMessage) setErrorMessage(false);
  };

  //Returns you back to page 1 if you haven't completed page 1 yet
  if (!isViewable) {
    return <Redirect to="/1" />
  }

  return (
    <Flex>
      <FormContainer>
        <Flex
          width={{ md: "60%", base: "80%" }}
          flexDirection="column"
          justifySelf="center"
        >
          <LogoContainer />
          <Progress
            percent={20}
            showInfo={false}
            strokeColor={COLORS.blue.dark}
            strokeWidth="10px"
          />
          <FormHeading>Company Type</FormHeading>
          <SubHeading>Let's learn more about your company.</SubHeading>
          <Divider width="150px" mb="4" />
          <Radio.Group
            style={{ width: "100%" }}
            defaultValue={currentValue}
            value={currentValue}
          >
            <CompanySelectBox
              value="climateTech"
              icon={<IconClimateTech style={{ fill: COLORS.gray["600"] }} />}
              title="Climate Tech"
              isChecked={currentValue === "climateTech"}
              onClick={() => handleValueChange("climateTech")}
            />
            <CompanySelectBox
              value="fund"
              icon={<IconFund style={{ fill: COLORS.gray["600"] }} />}
              title="Funder or Investor"
              isChecked={currentValue === "fund"}
              onClick={() => handleValueChange("fund")}
            />
            <CompanySelectBox
              value="other"
              icon={<IconOther style={{ fill: COLORS.gray["600"] }} />}
              title="Other Organization or Business"
              isChecked={currentValue === "other"}
              onClick={() => handleValueChange("other")}
            />
          </Radio.Group>
          {errorMessage && (
            <Text color="red.accent">Please select your company type</Text>
          )}
          <Flex
            width="100%"
            alignSelf="center"
            justifyContent="space-between"
            mt="8"
          >
            <Link to="/1">
              <ButtonPrevious>Back</ButtonPrevious>
            </Link>
            <ButtonNext onClick={handleClick}>Next</ButtonNext>
          </Flex>
        </Flex>
      </FormContainer>
      <Sidebar />
    </Flex>
  );
};

const CompanySelectBox = ({ isChecked, onClick, value, icon, title }) => {
  const selectStyle = useSpring({
    borderColor: isChecked ? COLORS.blue.selection : COLORS.gray["100"],
    config: {
      duration: 200,
    },
  });

  const AnimatedFlex = animated(Flex);

  return (
    <>
      <AnimatedFlex
        width="100%"
        height="125px"
        py="2"
        px="4"
        mb="2"
        border="1px solid"
        borderRadius="2px"
        onClick={onClick}
        _hover={{ cursor: "pointer" }}
        style={{ ...selectStyle }}
      >
        <Radio value={value} />
        <Flex alignItems="center" ml="3">
          {icon}
        </Flex>
        <Flex alignItems="center" ml="5">
          <Heading fontSize="28px" fontWeight="normal">
            {title}
          </Heading>
        </Flex>
      </AnimatedFlex>
    </>
  );
};

export default FormCompanyTypePage;
