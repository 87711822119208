import React from "react";
import { Flex, Divider, Text } from "@chakra-ui/react";
import { Progress, Form, Checkbox } from "antd";
import { FormLabel, FormHeading, SubHeading } from "../General/Styled";
import COLORS from "../../colors";
import ButtonGroup from "../General/ButtonGroup";

//Router and Redux
import { updateFormSubsection, updateCustomizedSubsection } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const GOALS_OPTIONS = [
  "Build a sustainability strategy",
  "Launch a sustainability product",
  "Improve a sustainability product",
  "Other"
];

const CTechGoalsPage = (props) => {
  const formState = useSelector((state) => state.form.climateTech);
  const dispatch = useDispatch();

  const nextPage = () => {
    props.history.push("/confirm");
  };

  return (
    <>
      <Progress
        percent={90}
        showInfo={false}
        strokeColor={COLORS.blue.dark}
        strokeWidth="10px"
      />
      <FormHeading>Goals</FormHeading>
      <SubHeading>
        Almost done. This question adds context for our upcoming meeting.
      </SubHeading>
      <Divider width="150px" mb="4" />
      <Form
        onFinish={(val) => {
          console.log(val);
          dispatch(updateFormSubsection("climateTech", val));
          dispatch(updateCustomizedSubsection("climateTech", "goals"));
          nextPage();
        }}
      >
        <FormLabel>What goal do you hope to achieve?</FormLabel>
        <Form.Item
          name="goal"
          id="goal"
          rules={[
            {
              required: true,
              message: "Please choose your goal",
            },
          ]}
          initialValue={formState.goal && formState.goal}
          style={{ marginBottom: "1em" }}
        >
          <Checkbox.Group>
            {GOALS_OPTIONS.map((productType) => (
              <Flex mb="1">
                <Checkbox value={productType}>
                  <Text fontSize="16px">{productType}</Text>
                </Checkbox>
              </Flex>
            ))}
          </Checkbox.Group>
        </Form.Item>
        <ButtonGroup backLink="/5" />
      </Form>
    </>
  );
};

export default CTechGoalsPage;
