import React from "react";

// Components
import { VStack, Image, Link } from "@chakra-ui/react";
import { ItemHeading, ItemSubheading } from "./Styled";

const CarouselItem = (props) => {
  return (
    <Link href={props.href} isExternal style={{ textDecoration: 'none' }}>
    <VStack spacing={0}>
        <Image w="300px" h="360px" borderRadius="150px" src={props.src} fit="cover" textDecoration="none"/>
        {/* <ItemHeading>{props.heading}</ItemHeading> */}
        <ItemSubheading>{props.subheading}</ItemSubheading>
    </VStack>
    </Link>
  );
};

export default CarouselItem;

