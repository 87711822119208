import React from "react";

// Components
import { Flex } from "@chakra-ui/react";
import Heading from "./Heading";
import CarouselItem from "../General/CarouselItem";
import { CaseStudies } from "../General/constants";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import SwiperCore, { Mousewheel } from "swiper/core";

// install Swiper modules
SwiperCore.use(Mousewheel);

const CarouselSlide = (props) => {
  const CarouselItems = CaseStudies.map((item) => (
    <SwiperSlide>
      <CarouselItem {...item} />
    </SwiperSlide>
  ));

  return (
    <Flex flexDir="column" alignItems="center" pt="min(1rem)" my={{base: 0, xl: 10}}>
      <Heading
        title="We’ve helped big and small companies alike."
        description="We know there isn’t a one-size-fits-all solution - that’s why we customize our approach to fit your needs."
      />

      <Flex
        sx={{
          ".swiper-slide": {
            width: "auto",
          },
          ".swiper-container": {
            paddingLeft: "10%",
            paddingRight: "10%",
          },
        }}
        my={12}
        justify="center"
        width="100%"
        height="100%"
        alignItems="center"
      >
        <Swiper
          // direction={"horizontal"}
          // mousewheel={true}
          slidesPerView={"auto"}
          spaceBetween={50}
          freeMode={true}
        >
          {CarouselItems}
        </Swiper>
      </Flex>
    </Flex>
  );
};

export default CarouselSlide;
