import React from "react";
import { Flex, Divider, Text } from "@chakra-ui/react";
import { Progress, Form, Select, Radio } from "antd";
import { FormLabel, FormHeading } from "../General/Styled";
import COLORS from "../../colors";
import ButtonGroup from "../General/ButtonGroup";

//Router and Redux
import {
  updateFormSubsection,
  updateCustomizedSubsection,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;

const PRODUCT_TYPE_OPTIONS = ["Hardware", "Software", "Both"];

const PROJECT_NEEDS_OPTIONS = [
  "Strategy & Research",
  "Materiality Assessments",
  "Life Cycle Analysis & Modelling",
  "Measurement & Reporting",
  "Market Entry Strategy",
  "Business Development",
  "Technology Setup & Integration",
  "Operations & Change Management",
  "Climate & Environmental Strategy",
  "Due Diligence Support",
  "Impact Measurement & Reporting",
  "Portfolio Management Support",
];

const CTechProjectSpecsPage = (props) => {
  const formState = useSelector((state) => state.form.climateTech);
  const dispatch = useDispatch();

  const nextPage = () => {
    props.history.push("/6");
  };

  return (
    <>
      <Progress
        percent={80}
        showInfo={false}
        strokeColor={COLORS.blue.dark}
        strokeWidth="10px"
      />
      <FormHeading mb="4">Project Specifications</FormHeading>
      <Divider width="150px" mb="4" />
      <Form
        onFinish={(val) => {
          console.log(val);
          dispatch(updateFormSubsection("climateTech", val));
          dispatch(updateCustomizedSubsection("climateTech", "projectSpecs"));
          nextPage();
        }}
      >
        <FormLabel>
          What type of product or service does your company sell?
        </FormLabel>
        <Form.Item
          name="productType"
          id="productType"
          rules={[
            {
              required: true,
              message: "Please choose your product type",
            },
          ]}
          initialValue={formState.productType && formState.productType}
          style={{ marginBottom: "1em" }}
        >
          <Radio.Group>
            {PRODUCT_TYPE_OPTIONS.map((productType) => (
              <Flex mb="1">
                <Radio value={productType}>
                  <Text fontSize="16px">{productType}</Text>
                </Radio>
              </Flex>
            ))}
          </Radio.Group>
        </Form.Item>
        <FormLabel>What can we help with? (Select all that apply)</FormLabel>
        <Form.Item
          name="projectNeed"
          id="projectNeed"
          rules={[
            {
              required: true,
              message: "Please input your project needs",
            },
          ]}
          initialValue={formState.projectNeed && formState.projectNeed}
        >
          <Select mode="multiple" size="large" placeholder="Select Areas">
            {PROJECT_NEEDS_OPTIONS.map((projectNeed) => (
              <Option value={projectNeed}>{projectNeed}</Option>
            ))}
          </Select>
        </Form.Item>
        <ButtonGroup backLink="/4" />
      </Form>
    </>
  );
};

export default CTechProjectSpecsPage;
