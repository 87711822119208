import React from "react";
import { Divider, Flex, Text } from "@chakra-ui/react";
import { Progress, Form, Radio, Select} from "antd";
import { FormLabel, FormHeading } from "../General/Styled";
import COLORS from "../../colors";
import ButtonGroup from "../General/ButtonGroup";

//Router and Redux
import {
  updateFormSubsection,
  updateCustomizedSubsection,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;

 const PROJECT_TYPE_OPTIONS = [
  "New idea or project",
  "Existing project that needs assistance",
  "Ongoing consultation",
];

const PROJECT_LENGTH_OPTIONS = [
  "Less than 1 month",
  "1-3 months",
  "3-6 months",
  "6 months to 1 year",
  "Greater than 1 year",
  "I'm not sure"
];

const OtherProjectSpecsPage = (props) => {
  const formState = useSelector((state) => state.form.other);
  const dispatch = useDispatch();

  const nextPage = () => {
    props.history.push("/5");
  };

  return (
    <>
      <Progress
        percent={60}
        showInfo={false}
        strokeColor={COLORS.blue.dark}
        strokeWidth="10px"
      />
      <FormHeading mb="4">Project Specifications</FormHeading>
      <Divider width="150px" mb="4" />
      <Form
        onFinish={(val) => {
          console.log(val);
          dispatch(updateFormSubsection("other", val));
          dispatch(updateCustomizedSubsection("other", "projectSpecs"));
          nextPage();
        }}
      >
        <FormLabel>What are you hiring for?</FormLabel>
        <Form.Item
          name="projectType"
          id="projectType"
          rules={[
            {
              required: true,
              message: "Please input your project type",
            },
          ]}
          initialValue={formState.projectType && formState.projectType}
          style={{ marginBottom: "1em" }}
        >
          <Radio.Group>
            {PROJECT_TYPE_OPTIONS.map((projectType) => (
              <Flex mb="1">
                <Radio value={projectType}>
                  <Text fontSize="16px">{projectType}</Text>
                </Radio>
              </Flex>
            ))}
          </Radio.Group>
        </Form.Item>
        <FormLabel>What is your expected timeframe?</FormLabel>
        <Form.Item
          name="projectLength"
          id="projectLength"
          rules={[
            {
              required: true,
              message: "Please input your estimated project length",
            },
          ]}
          initialValue={formState.projectLength && formState.projectLength}
        >
          <Select size="large" placeholder="Time Estimate">
            {PROJECT_LENGTH_OPTIONS.map((projectLength) => (
              <Option value={projectLength}>{projectLength}</Option>
            ))}
          </Select>
        </Form.Item>
        <ButtonGroup backLink="/3" />
      </Form>
    </>
  );
};

export default OtherProjectSpecsPage;
