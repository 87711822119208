import React from "react";

import { Flex } from "@chakra-ui/react";
import Heading from "./Heading";
import ProfileCard from "../General/ProfileCard";

const ProfileSlide = (props) => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      pt="min(1rem)"
      my={10}
      id="experts"
    >
      <Heading
        title="Here are the experts we handpicked for you."
        description="All of our experts have gone through a strict vetting process so you know they’re good at what they do!"
      />

      <Flex justifyContent="center">
        <ProfileCard data={props.data} />
      </Flex>
    </Flex>
  );
};

export default ProfileSlide;
