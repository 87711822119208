import React from "react";
import { Flex } from "@chakra-ui/react";
import { CTechGoalsPage } from "../ClimateTech";
import { FormContainer } from "../General/Styled";
import Sidebar from "../General/Sidebar";
import LogoContainer from "../General/LogoContainer";

//Redux
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const FormPageSixContainer = (props) => {
  const companyType = useSelector((state) => state.form.companyType);

  //Checks if the companyType page has been visited
  const isViewable = useSelector((state) => state.formCompletion);

  if (!isViewable.climateTech.projectSpecs) {
    console.log("REDIRECTING!");
    return <Redirect to="/1" />;
  }

  return (
    <Flex>
      <FormContainer>
        <Flex
          width={{ md: "60%", base: "80%" }}
          flexDirection="column"
          justifySelf="center"
        >
          <LogoContainer />
          {
            /**
             *  Make sure to pass {...props} to all components so that they can access
             *  route navigation
             */
            companyType === "climateTech" ? (
              <CTechGoalsPage {...props} />
            ) : (
              <Redirect to="/1" />
            )
          }
        </Flex>
      </FormContainer>
      <Sidebar />
    </Flex>
  );
};

export default FormPageSixContainer;
