import React from "react";

import { Flex, Divider, Text } from "@chakra-ui/react";
import { Progress, Form, Checkbox } from "antd";
import { FormLabel, FormHeading, SubHeading } from "../General/Styled";
import COLORS from "../../colors";
import ButtonGroup from "../General/ButtonGroup";

//Router and Redux
import { updateFormSubsection, updateCustomizedSubsection } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

/**
 * Todo: might have to optimize options to make values easier to manage
 */
const FUND_OPTIONS = [
  "Perform due diligence",
  "Create a climate finance strategy",
  "Assist portfolio companies",
  "Other"
];

const FundGoalsPage = (props) => {
  const formState = useSelector((state) => state.form.fund);
  const dispatch = useDispatch();

  const nextPage = () => {
    props.history.push("/confirm");
  };

  return (
    <>
      <Progress
        percent={80}
        showInfo={false}
        strokeColor={COLORS.blue.dark}
        strokeWidth="10px"
      />
      <FormHeading>Your Goals</FormHeading>
      <SubHeading>
        Almost done. This question adds context for our upcoming meeting.
      </SubHeading>
      <Divider width="150px" mb="4" />
      <Form
        onFinish={(val) => {
          console.log(val);
          dispatch(updateFormSubsection("fund", val));
          dispatch(updateCustomizedSubsection("fund", "goals"));
          nextPage();
        }}
      >
        <FormLabel>How can our experts help you?</FormLabel>
        <Form.Item
          name="fundGoals"
          id="fundGoals"
          rules={[
            {
              required: true,
              message: "Please input your fund's goals",
            },
          ]}
          initialValue={formState.fundGoals && formState.fundGoals}
          style={{ marginBottom: "1em" }}
        >
          <Checkbox.Group>
            {FUND_OPTIONS.map((fundGoals) => (
              <Flex mb="1">
                <Checkbox value={fundGoals}>
                  <Text fontSize="16px"> {fundGoals} </Text>
                </Checkbox>
              </Flex>
            ))}
          </Checkbox.Group>
        </Form.Item>
        <ButtonGroup backLink="/4" />
      </Form>
    </>
  );
};

export default FundGoalsPage;
