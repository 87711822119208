import Investment from "../../assets/investment.png";
import Recycle from "../../assets/recycle.png";
import Urban from "../../assets/urban.png";
import Lab from "../../assets/lab.png";
import Packaging from "../../assets/packaging.png";
import Science from "../../assets/science.png";
import Shelves from "../../assets/shelves.png";

export const Profiles = [
  {
    "First Name": "Neil",
    "Last Name": "Yeoh",
    "Profile Photo": [{url: "https://dl.airtable.com/.attachments/0f754b1c1012f6722f14e2ce64d2e4ec/476c5695/ProfilePicture_1.jpg"}],
    "Position": "Team Lead",
    "About Me":
      "Neil is the CEO, Founder of OnePointFive (opf.degree), a remote advisory network of multidisciplinary sustainability experts. Neil is a sustainability advisor, investor, and content creator. He served as a Startup Advisor for Yale University's Center of Business and Environment (CBEY), providing mentorship to early-stage startups. Prior to this, Neil was a Deputy Director at Echoing Green who managed a $8.5mn climate startup investment portfolio, and led the startup post-investment support department. Over three years, he reviewed over 2,000+ climate-related startup applications, and invested in and advised 70 climate startups across 20 countries. He also helped these startups raise millions collectively to bring their solutions from lab to market. Neil writes for Forbes on next-generation climate solutions and startup investing, accumulating over 100,000 reads of his articles. He has been invited to speak on business and environment topics at the United Nations, Oxford, Harvard, Yale, New York, and Princeton universities. He has over five years of management consulting experience across four continents with Dalberg, Accenture, and KPMG. Neil has an Executive Masters of Environmental Management from Yale University and has an MBA from Oxford University. He also has degrees in Robotics Engineering, International Business, and Management from Adelaide and Hong Kong Universities.",
    "Location": "New York, USA",
    "Work Experience":
      "Echoing Green, Yale University, Forbes, World Economic Forum, Dalberg, KPMG, Accenture",
    "Sustainability Expertise": [
      "Sustainability Strategy",
      "Climate Tech Commercialization",
      "Sustainability Technical",
      "Sustainability Operations",
      "Climate Tech Sourcing & Integration",
      "Technical Research & Assistance",
      "Expert Insights & Climate Intelligence",
    ],
    "Industry Expertise": [
      "Nonprofit Organization Management",
      "Venture Capital",
      "Environmental Services",
      "Financial Services",
      "Management Consulting",
      "Oil & Energy",
    ],
    "Sustainability Service Expertise": [
      "Strategy & Research",
      "Materiality Assessments",
      "Life Cycle Analysis & Modeling",
      "Measurement & Reporting",
      "Market Entry Strategy",
      "Business Development",
      "Technology Setup & Integration",
      "Operations & Change Management",
      "Climate & Environmental Strategy",
      "Due Diligence Support",
      "Impact Measurement & Reporting",
      "Portfolio Management Support",
    ],
  },
  {
    "First Name": "Matthias",
    "Last Name": "Muehlbauer",
    "Profile Photo": [{url: "https://dl.airtable.com/.attachments/c4f517f8f56bf337b4e30a540e6f8687/7fc2af25/MatthiasMuehlbauerHeadshot.png"}],
    "Position": "Senior Manager",
    "About Me":
      "Matthias is an ex-founder with a global background in both corporate and early-stage startup environments, having worked in Management Consulting at PwC in both Australia and Japan working with multinational corporations. His time at OnePointFive as a strategy consultant have included work for an Impact VC performing climate technology and commercial due diligence on an early stage investment, and US market entry project for a Climate AI and machine learning platform from London focused on predicting physical asset risk from a climate lens. Prior to OnePointFive, he graduated as a MEXT Japan Young Leaders Scholar from Japan’s top MBA school, Hitotsubashi University and completed a year at Yale’s School of Management as a Yale Macmillan CEAS Fellow. Recently, as Finance and Software Lead, Matthias worked with Yale early-stage startup Cambium Carbon building natural climate solutions by reimagining the urban forestry lifecycle to drive carbon sequestration. He has a passion for sustainability and global issues and is an Executive Committee member of the Australia Japan Youth Dialogue where he oversees strategy and fundraising. Matthias holds a Master of Advanced Management from Yale University, a Master of Business Administration from Hitotsubashi University, a Master of Professional Accounting from Monash University and a Bachelor of Arts (Economics and Psychology) from the University of Melbourne. He is a Chartered Accountant with CAANZ. He has lived in 7 different countries and is a recovering mono-linguist currently learning Japanese.",
    "Location": "New York, New York",
    "Work Experience": "Cambium Carbon, Yale University, PwC",
    "Sustainability Expertise": [
      "Sustainability Strategy",
      "Climate Tech Commercialization",
      "Sustainability Technical",
      "Sustainability Operations",
      "Climate Tech Sourcing & Integration",
      "Technical Research & Assistance",
      "Expert Insights & Climate Intelligence",
    ],
    "Industry Expertise": [
      "Accounting",
      "Capital Markets",
      "Financial Services",
      "Management Consulting",
      "Environmental Services",
      "Computer Software",
    ],
    "Sustainability Service Expertise": [
      "Strategy & Research",
      "Market Entry Strategy",
      "Business Development",
      "Technology Setup & Integration",
      "Climate & Environmental Strategy",
      "Due Diligence Support",
      "Portfolio Management Support",
      "Operations & Change Management",
      "Measurement & Reporting",
    ],
  },
];

export const CaseStudies = [
  {
    src: Investment,
    heading: "Placeholder 1",
    subheading:
      "Due Diligence on Climate Technology Venture for Pre-Series A Investment",
    href: "https://www.opf.degree/our-blog/due-diligence-on-climate-technology-venture-for-series-a-investment",
  },
  {
    src: Recycle,
    heading: "Placeholder 2",
    subheading:
      "Recycled Material Cost, Environmental Benefits & Investment Analysis Tool",
    href: "https://www.opf.degree/our-blog/c7nousxdaxtakkx2hc4kdpq2f3yi69",
  },
  {
    src: Urban,
    heading: "Placeholder 3",
    subheading:
      "Climate Physical Risk Technology: US Market Entry & Lead Generation",
    href: "https://www.opf.degree/our-blog/due-diligence-on-climate-technology-venture-for-series-a-investment-ebgeb",
  },
  {
    src: Lab,
    heading: "Placeholder 4",
    subheading:
      "Industry Report on Considerations When Investing in Nascent Climate Technologies",
    href: "https://www.opf.degree/our-blog/industry-report-on-considerations-when-investing-in-nascent-climate-technologies",
  },
  {
    src: Packaging,
    heading: "Placeholder 5",
    subheading: "Green Engineering Product Packaging Material Analysis",
    href: "https://www.opf.degree/our-blog/green-engineering-product-packaging-material-analysis",
  },
  {
    src: Science,
    heading: "Placeholder 6",
    subheading: "Green Chemistry Analysis of Product Formula and Ingredients",
    href: "https://www.opf.degree/our-blog/green-chemistry-analysis-of-product-formula-and-ingredients",
  },
  {
    src: Shelves,
    heading: "Placeholder 7",
    subheading: "Green Engineering Redesign of Product Service Delivery",
    href: "https://www.opf.degree/our-blog/rul8joq9rtd6id1ggiqoh0ogmgl9o8",
  },
];

export const CLIMATE_INDUSTRIES = [
  "AI & Machine Learning",
  "Analytics & Climate Intelligence",
  "Clean Energy & Storage",
  "Data Science & Modelling",
  "Communications & Advocacy",
  "Sustainable Food & Agriculture",
  "Land Management & Forests",
  "Green Buildings & Cities",
  "Circular Economy & Supply Chains",
  "Industrials & Transportation",
  "Water & Oceans Management",
  "Climate - Other",
];

export const GENERAL_INDUSTRIES = [
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-learning",
  "Electrical & Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fundraising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Banking/Venture",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure & Travel",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing & Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Publishing",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Policy",
  "Public Relations",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational",
  "Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing",
];
