import React from "react";
import { Flex } from "@chakra-ui/react";
import { ButtonNext, ButtonPrevious } from "../General/Styled";
import { Link } from "react-router-dom";

const ButtonGroup = ({ backLink }) => (
  <Flex mt="8" width="100%" alignSelf="center" justifyContent="space-between">
    <Link to={backLink}>
      <ButtonPrevious>Back</ButtonPrevious>
    </Link>
    <ButtonNext>Next</ButtonNext>
  </Flex>
);

export default ButtonGroup;