import React from "react";

import { Flex, Divider, Text } from "@chakra-ui/react";
import { Progress, Form, Select, Radio } from "antd";
import { FormLabel, FormHeading, SubHeading } from "../General/Styled";
import COLORS from "../../colors";
import ButtonGroup from "../General/ButtonGroup";

//Router and Redux
import {
  updateFormSubsection,
  updateCustomizedSubsection,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;

/**
 * Todo: might have to optimize options to make values easier to manage
 */
const FUND_OPTIONS = ["Family Office", "Incubator or Accelerator", "Venture Capital", "Venture Philanthropy", "Private Equity", "Other"];

const FUND_FOCUS_OPTIONS = [
  "Pre-seed/Seed",
  "Series A",
  "Series B",
  "Series C",
  "Late Stage (Series D or later)",
  "Public listed companies",
];

const FundTypePage = (props) => {
  const formState = useSelector((state) => state.form.fund);
  const dispatch = useDispatch();

  const nextPage = () => {
    props.history.push("/4");
  };

  return (
    <>
      <Progress
        percent={40}
        showInfo={false}
        strokeColor={COLORS.blue.dark}
        strokeWidth="10px"
      />
      <FormHeading>Fund Details</FormHeading>
      <SubHeading>
        Great to have you here. The following questions will help us better
        tailor our services for your{" "}
        <Text as="span" fontWeight="semibold">
          Fund
        </Text>
        .
      </SubHeading>
      <Divider width="150px" mb="4" />
      <Form
        onFinish={(val) => {
          console.log(val);
          dispatch(updateFormSubsection("fund", val));
          dispatch(updateCustomizedSubsection("fund", "fundType"));
          nextPage();
        }}
      >
        <FormLabel>Fund Type</FormLabel>
        <Form.Item
          name="fundType"
          id="fundType"
          rules={[
            {
              required: true,
              message: "Please input your fund type",
            },
          ]}
          initialValue={formState.fundType && formState.fundType}
          style={{ marginBottom: "1em" }}
        >
          <Radio.Group>
            {FUND_OPTIONS.map((fundType) => (
              <Flex mb="1">
                <Radio value={fundType}>
                  <Text fontSize="16px"> {fundType} </Text>
                </Radio>
              </Flex>
            ))}
          </Radio.Group>
        </Form.Item>

        <FormLabel>Investment Focus</FormLabel>
        <Form.Item
          name="fundFocus"
          id="fundFocus"
          rules={[
            {
              required: true,
              message: "Please input your fund's investment focus",
            },
          ]}
          initialValue={formState.fundFocus && formState.fundFocus}
        >
          <Select mode="multiple" placeholder="Investment Focus" size="large">
            {FUND_FOCUS_OPTIONS.map((fundFocus) => (
              <Option value={fundFocus}>{fundFocus}</Option>
            ))}
          </Select>
        </Form.Item>

        <ButtonGroup backLink="/2" />
      </Form>
    </>
  );
};

export default FundTypePage;
