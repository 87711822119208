import React from "react";
import OPFLogo from "../../assets/logoOPF.png";
import { Flex, Image } from "@chakra-ui/react";

const LogoContainer = (props) => (
  <Flex width="100%" justifyContent="center" mb="8">
    <Image src={OPFLogo} alt="OPF Logo" boxSize="90px" />
  </Flex>
);

export default LogoContainer;
