import React from "react";

// Components
import { Flex } from "@chakra-ui/react";
import Heading from "./Heading";
import { InlineWidget } from "react-calendly";

const CalendlySlide = (props) => {
  return (
    <>
      <Flex flexDir="column" alignItems="center" pt="min(1rem)" my={10}>
      <Heading
        title="let’s find a time to talk!"
        description="Set up a call with us to get started."
      />

        <Flex justifyContent="center" width="100%" mt="3">
          <InlineWidget
            styles={{ width: "100%", height: "650px" }}
            url="https://calendly.com/opfdegree/intro"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default CalendlySlide;
