import React, { useState } from "react";

import { Flex, Divider, Text } from "@chakra-ui/react";
import { Progress, Form, Select, Radio } from "antd";
import { FormLabel, FormHeading } from "../General/Styled";
import { CLIMATE_INDUSTRIES, GENERAL_INDUSTRIES } from "../General/constants";
import COLORS from "../../colors";
import ButtonGroup from "../General/ButtonGroup";

//Router and Redux
import {
  updateFormSubsection,
  updateCustomizedSubsection,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const { Option, OptGroup } = Select;

const FundFocusPage = (props) => {
  const formState = useSelector((state) => state.form.fund);
  const dispatch = useDispatch();

  const [isExperience, setExperience] = useState(() => {
    if (formState.hasOwnProperty("hasFundedClimate"))
      return formState.hasFundedClimate;
    else return null;
  });

  const nextPage = () => {
    props.history.push("/5");
  };

  return (
    <>
      <Progress
        percent={60}
        showInfo={false}
        strokeColor={COLORS.blue.dark}
        strokeWidth="10px"
      />
      <FormHeading mb="4">Fund Focus</FormHeading>
      <Divider width="150px" mb="4" />
      <Form
        onFinish={(val) => {
          console.log(val);
          dispatch(updateFormSubsection("fund", val));
          dispatch(updateCustomizedSubsection("fund", "focus"));
          nextPage();
        }}
      >
        <FormLabel>Have you funded climate companies?</FormLabel>
        <Form.Item
          name="hasFundedClimate"
          id="hasFundedClimate"
          rules={[
            {
              required: true,
              message: "Please input your fund type",
            },
          ]}
          initialValue={
            formState.hasFundedClimate && formState.hasFundedClimate
          }
          style={{ marginBottom: "1em" }}
        >
          <Radio.Group
            value={isExperience}
            onChange={(e) => {
              setExperience(e.target.value);
            }}
          >
            <Flex mb="1">
              <Radio value={true}>
                <Text fontSize="16px">Yes</Text>
              </Radio>
            </Flex>
            <Flex mb="1">
              <Radio value={false}>
                <Text fontSize="16px">No</Text>
              </Radio>
            </Flex>
          </Radio.Group>
        </Form.Item>

        {isExperience !== null && (
          <>
            <FormLabel>
              {isExperience
                ? "What areas have you invested in?"
                : "What areas are you interested in investing in?"}
            </FormLabel>
            <Form.Item
              name="investmentAreas"
              id="investmentAreas"
              rules={[
                {
                  required: true,
                  message: isExperience
                    ? "Please input your fund's investment areas"
                    : "Please input your fund's areas of interest",
                },
              ]}
              initialValue={
                formState.investmentAreas && formState.investmentAreas
              }
              extra="Search for investment areas of interest"
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Investment Focus"
                size="large"
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
              >
                <OptGroup label="Climate Tech">
                  {CLIMATE_INDUSTRIES.map((domain) => (
                    <Option value={domain}>{domain}</Option>
                  ))}
                </OptGroup>
                <OptGroup label="General">
                  {GENERAL_INDUSTRIES.map((domain) => (
                    <Option value={domain}>{domain}</Option>
                  ))}
                </OptGroup>
              </Select>
            </Form.Item>
          </>
        )}
        <ButtonGroup backLink="/3" />
      </Form>
    </>
  );
};

export default FundFocusPage;
