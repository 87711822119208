import React from "react";
import { Flex, Divider } from "@chakra-ui/react";
import { Progress, Form, Input, Checkbox } from "antd";
import {
  FormContainer,
  FormLabel,
  FormHeading,
  SubHeading,
  ButtonNext,
  ButtonPrevious,
} from "../General/Styled";
import Sidebar from "../General/Sidebar";
import LogoContainer from "../General/LogoContainer";

//Router and Redux
import { Redirect } from "react-router-dom";
import { updateFormStandard, updateStandardSection } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const FormConfirmationPage = (props) => {
  const dispatch = useDispatch();
  const isViewable = useSelector((state) => state.formCompletion)

  const nextPage = () => {
    props.history.push("/results");
  };

  if (
    !isViewable.climateTech.goals &&
    !isViewable.fund.goals &&
    !isViewable.other.goals
  ) {
    return <Redirect to="/1" />;
  }

  return (
    <Flex>
      <FormContainer>
        <Flex
          width={{ md: "60%", base: "80%" }}
          flexDirection="column"
          justifySelf="center"
        >
          <LogoContainer />

          <Progress percent={100} showInfo={false} strokeWidth="10px" />

          <FormHeading>Done!</FormHeading>
          <SubHeading>
            Thanks for expressing interest. Enter your email below and one of
            our team members will be in touch.
          </SubHeading>

          <Divider width="150px" mb="4" />

          <Form
            onFinish={(val) => {
              console.log(val);
              dispatch(updateFormStandard(val));
              dispatch(updateStandardSection("contact"));
              nextPage();
            }}
          >
            <FormLabel>Name</FormLabel>
            <Form.Item
              name="name"
              id="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name",
                },
              ]}
              style={{ marginBottom: "1em" }}
            >
              <Input placeholder="Name" size="large" />
            </Form.Item>

            <FormLabel>Company name</FormLabel>
            <Form.Item
              name="companyName"
              id="companyName"
              rules={[
                {
                  required: true,
                  message: "Please input your company name",
                },
              ]}
              style={{ marginBottom: "1em" }}
            >
              <Input placeholder="Company name" size="large" />
            </Form.Item>

            <FormLabel>Email</FormLabel>
            <Form.Item
              name="email"
              id="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
              style={{ marginBottom: "1em" }}
            >
              <Input placeholder="Email" size="large" />
            </Form.Item>

            <Form.Item
              name="termsAndConditions"
              valuePropName="checked"
              id="termsAndConditions"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Please give permission to access your form results")
                        ),
                },
              ]}
              style={{ marginBottom: "1em" }}
            >
              <Checkbox>
                I give permission to OnePointFive to store the information on this form for internal use only.
              </Checkbox>
            </Form.Item>

            <Flex mt="8" alignSelf="center" justifyContent="space-between">
              <ButtonPrevious
                onClick={() => {
                  window.history.back();
                }}
              >
                Back
              </ButtonPrevious>
              <ButtonNext>Next</ButtonNext>
            </Flex>
          </Form>
        </Flex>
      </FormContainer>
      <Sidebar />
    </Flex>
  );
};

export default FormConfirmationPage;
