import React from "react";
import { Icon } from "@chakra-ui/react";

const LocationIcon = (props) => {
  return (
    <Icon
      width="5"
      height="5"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0274 1.40234C9.02238 1.40471 7.1002 2.20223 5.68247 3.61996C4.26474 5.03769 3.46722 6.95987 3.46485 8.96484C3.46245 10.6033 3.99765 12.1973 4.98835 13.5023C4.98835 13.5023 5.1946 13.7739 5.22829 13.8131L11.0274 20.6523L16.8292 13.8097C16.8594 13.7732 17.0664 13.5023 17.0664 13.5023L17.067 13.5003C18.0572 12.1958 18.5922 10.6026 18.5899 8.96484C18.5875 6.95987 17.79 5.03769 16.3722 3.61996C14.9545 2.20223 13.0323 1.40471 11.0274 1.40234ZM11.0274 11.7148C10.4835 11.7148 9.95177 11.5536 9.49953 11.2514C9.0473 10.9492 8.69482 10.5197 8.48668 10.0172C8.27854 9.51473 8.22408 8.96179 8.33019 8.42835C8.4363 7.8949 8.69821 7.40489 9.08281 7.0203C9.4674 6.63571 9.95741 6.37379 10.4909 6.26768C11.0243 6.16157 11.5772 6.21603 12.0797 6.42417C12.5822 6.63232 13.0117 6.98479 13.3139 7.43703C13.6161 7.88926 13.7774 8.42095 13.7774 8.96484C13.7764 9.69391 13.4864 10.3929 12.9709 10.9084C12.4554 11.4239 11.7564 11.7139 11.0274 11.7148Z"
        fill="#656D75"
      />
    </Icon>
  );
};

export default LocationIcon;
