import { Divider, Flex, Text } from "@chakra-ui/react";
import { Progress, Form, Select, Radio } from "antd";
import { FormLabel, FormHeading, SubHeading } from "../General/Styled";
import { CLIMATE_INDUSTRIES, GENERAL_INDUSTRIES } from "../General/constants";
import COLORS from "../../colors";
import React from "react";
import ButtonGroup from "../General/ButtonGroup";

//Router and Redux
import {
  updateFormSubsection,
  updateCustomizedSubsection,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const { Option, OptGroup } = Select;

/**
 * Todo: might have to optimize options to make values easier to manage
 */

const PRODUCT_OPTIONS = ["Products", "Services", "Both"];

const DOMAIN_OPTIONS = [
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-learning",
  "Electrical & Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fundraising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Banking/Venture",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure & Travel",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing & Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Publishing",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Policy",
  "Public Relations",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational",
  "Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing",
];

const SIZE_OPTIONS = ["1-10", "11-50", "51-200", "200-1000", "1000+"];

const OtherCompanyTypePage = (props) => {
  const formState = useSelector((state) => state.form.other);
  const dispatch = useDispatch();

  const nextPage = () => {
    props.history.push("/4");
  };

  return (
    <>
      <Progress
        percent={40}
        showInfo={false}
        strokeColor={COLORS.blue.dark}
        strokeWidth="10px"
      />
      <FormHeading>Organization Details</FormHeading>
      <SubHeading>
        Great to have you here. The following questions will help us better
        tailor our services for your company.
      </SubHeading>
      <Divider width="150px" mb="4" />
      <Form
        onFinish={(val) => {
          console.log(val);
          dispatch(updateFormSubsection("other", val));
          dispatch(updateCustomizedSubsection("other", "companyType"));
          nextPage();
        }}
      >
        <FormLabel>My Company Offers</FormLabel>
        <Form.Item
          name="productType"
          id="productType"
          rules={[
            {
              required: true,
              message: "Please input your product type",
            },
          ]}
          style={{ marginBottom: "1em" }}
          initialValue={formState.productType && formState.productType}
        >
          <Radio.Group>
            {PRODUCT_OPTIONS.map((prod, index) => (
              <Flex mb={index !== PRODUCT_OPTIONS.length - 1 && "1"}>
                <Radio value={prod}>
                  <Text fontSize="16px">{prod}</Text>
                </Radio>
              </Flex>
            ))}
          </Radio.Group>
        </Form.Item>

        <FormLabel>My Industry is</FormLabel>
        <Form.Item
          name="companyDomain"
          id="companyDomain"
          rules={[
            {
              required: true,
              message: "Please input your company domain",
            },
          ]}
          style={{ marginBottom: "1em" }}
          initialValue={formState.companyDomain && formState.companyDomain}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Company Domain"
            size="large"
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <OptGroup label="Climate Tech">
              {CLIMATE_INDUSTRIES.map((domain) => (
                <Option value={domain}>{domain}</Option>
              ))}
            </OptGroup>
            <OptGroup label="General">
              {GENERAL_INDUSTRIES.map((domain) => (
                <Option value={domain}>{domain}</Option>
              ))}
            </OptGroup>
          </Select>
        </Form.Item>
        <FormLabel>Company Size</FormLabel>
        <Form.Item
          name="companySize"
          id="companySize"
          rules={[
            {
              required: true,
              message: "Please input your company size",
            },
          ]}
          initialValue={formState.companySize && formState.companySize}
        >
          <Select placeholder="Company Size" size="large">
            {SIZE_OPTIONS.map((companySize) => (
              <Option value={companySize}>{companySize}</Option>
            ))}
          </Select>
        </Form.Item>
        <ButtonGroup backLink="/2" />
      </Form>
    </>
  );
};

export default OtherCompanyTypePage;
