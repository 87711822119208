import React from "react";

// Components
import { Flex, Box, Text } from "@chakra-ui/react";
import { SectionHeading, SectionSubheading } from "../General/Styled";
import TimelineHoverable from "./TimelineHoverable";
import Heading from "./Heading";
import { ReactComponent as IconArrow } from "../../assets/iconArrow.svg";

const TimelineSlide = (props) => {
  return (
    <>
      <Flex
        flexDir="column"
        alignItems="center"
        pt="min(1rem)"
        my={10}
        minHeight="750px"
      >
        <Heading
          title="we’re here to walk with you every step of the way."
          description="The given timeline will vary on a case-by-case basis, so we will do our best to accomodate your timeframe."
        />
        <Flex
          position="relative"
          alignItems="center"
          justifyContent="center"
          width="100%"
          flexDir="column"
          mt="5"
          mb="5"
          height="100%"
        >
          <Flex
            flexDir="column"
            position="absolute"
            display={{ base: "none", lg: "flex" }}
            top={{ base: "-40px", xl: "10" }}
            left="4%"
          >
            <Text
              fontSize={{ base: "18px", lg: "20px", xl: "24px" }}
              lineHeight="30px"
              fontWeight="semibold"
              textAlign="center"
            >
              Hover to <br /> see more!
            </Text>
            <Box display={{ base: "none", xl: "inherit" }} paddingLeft={6}>
              <IconArrow style={{ marginLeft: "15px", marginTop: "5px" }} />
            </Box>
          </Flex>
          <TimelineHoverable />
        </Flex>
      </Flex>
    </>
  );
};

export default TimelineSlide;
