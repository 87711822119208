import React from "react";
import { Flex } from "@chakra-ui/react";
import { CTechProjectSpecsPage } from "../ClimateTech";
import { FundGoalsPage } from "../Fund";
import { OtherGoalsPage } from "../Other";
import { FormContainer } from "../General/Styled";
import Sidebar from "../General/Sidebar";
import LogoContainer from "../General/LogoContainer";

//Redux
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const FormPageFiveContainer = (props) => {
  const companyType = useSelector((state) => state.form.companyType);

  //Checks if the companyType page has been visited
  const isViewable = useSelector(state => state.formCompletion);

  if (!isViewable.climateTech.projectType && !isViewable.fund.focus && !isViewable.other.projectSpecs) {
    return <Redirect to="/1" />
  }

  return (
    <Flex>
      <FormContainer>
        <Flex
          width={{ md: "60%", base: "80%" }}
          flexDirection="column"
          justifySelf="center"
        >
          <LogoContainer />
          {
            /**
             *  Make sure to pass {...props} to all components so that they can access
             *  route navigation
             */
            companyType === "climateTech" ? (
              <CTechProjectSpecsPage {...props} />
            ) : companyType === "fund" ? (
              <FundGoalsPage {...props} />
            ) : (
              <OtherGoalsPage {...props} />
            )
          }
        </Flex>
      </FormContainer>
      <Sidebar />
    </Flex>
  );
};

export default FormPageFiveContainer;
