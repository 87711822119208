import React, { useState } from "react";

// Components
import { Box, Flex } from "@chakra-ui/react";
import { TitleHeading } from "../General/Styled";
import { FaChevronDown } from "react-icons/fa";
import COLORS from "../../colors";
import { HashLink as Link } from "react-router-hash-link";

//Animations
import { useSpring, animated, config } from "react-spring";

const TitleSlide = (props) => {

  const AnimatedContainer = animated(Flex);

  const styleProps = useSpring({
    loop: true,
    reset: true,
    enter: {
      color: COLORS.blue.light,
      opacity: 1,
      transform: "translateY(0px)",
    },
    from: {
      color: COLORS.blue.light,
      opacity: 1,
      transform: "translateY(0px)",
    },
    to: [
      {
        color: COLORS.blue.light,
        opacity: 0.5,
        transform: "translateY(-25px)",
      },
      {
        color: COLORS.blue.light,
        opacity: 1,
        transform: "translateY(0px)",
      }
    ],
    delay: 0,
    config: {duration: 700},
  });

  return (
    <>
      <Flex minH={["50vh", "100vh"]} flexDir="column">
        <Flex
          borderBottomRadius="25px"
          w="100%"
          height="80vh"
          minHeight="300px"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          bg="blue.light"
        >
          <Flex mb="6">
            <TitleHeading textAlign="center" mx={{base: 100, xl:200}}>
              Welcome to Your New Sustainability Journey!
            </TitleHeading>
          </Flex>
          <Box bg="yellow.accent" w={{base: "120px", md: "150px", xl: "200px"}} h="4px" />
        </Flex>
        <Flex
          justifyContent="center"
          mt="10"
        >
          <Link smooth to='/results#experts'>
          <AnimatedContainer
            style={styleProps}
            width="100%"
            justifyContent="center"
          >
            <FaChevronDown size="50px" />
          </AnimatedContainer>
          </Link>
        </Flex>
      </Flex>
    </>
  );
};

export default TitleSlide;
