import React, { useState } from "react";
import {
  Flex,
  Box,
  Text,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  IconButton,
  Button,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ProfilePhoto from "./ProfilePhoto";
import { NameHeading, ProfileHeading } from "./Styled";
import { useTransition, animated, config } from "react-spring";
import LocationIcon from "../../assets/LocationIcon";
import WorkIcon from "../../assets/WorkIcon";
import { ReactComponent as NextArrow } from "../../assets/nextArrow.svg";
import { ReactComponent as BackArrow } from "../../assets/backArrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar, Mousewheel } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";

SwiperCore.use([Scrollbar, Mousewheel]);

const AnimatedContainer = animated(Flex);

const Tags = ({ tags, bg, color, modalText, modalColor }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return tags.length <= 3 ? (
    tags.map((item) => (
      <WrapItem key="">
        <Tag
          borderRadius={"full"}
          py={1.5}
          px={3}
          fontSize="12px"
          bg={bg}
          color={color}
        >
          {item}
        </Tag>
      </WrapItem>
    ))
  ) : (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="semibold" fontSize="20px">
              {modalText}
            </Text>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Flex pb="1" borderBottom="1px solid" borderColor="gray.50">
              <Text color="gray.200" fontSize="14px" fontWeight="semibold">
                {tags.length} AREAS
              </Text>
            </Flex>
            {tags.map((tag) => (
              <Flex
                w="100%"
                h="44px"
                borderBottom="1px solid"
                borderColor="gray.50"
              >
                <Flex pl="5px" pr="15px" alignItems="center">
                  <Box h="10px" w="10px" bg={modalColor} borderRadius="10px" />
                </Flex>
                <Flex flex="auto" alignItems="center">
                  <Text fontSize="16px">{tag}</Text>
                </Flex>
              </Flex>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              borderRadius="50px"
              bgColor="blue.light"
              color="white"
              key="back"
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {tags.slice(0, 3).map((item) => (
        <WrapItem>
          <Tag
            borderRadius={"full"}
            py={1.5}
            px={3}
            fontSize="12px"
            bg={bg}
            color={color}
          >
            {item}
          </Tag>
        </WrapItem>
      ))}
      <Tag
        borderRadius={"full"}
        py={1.5}
        px={3}
        fontSize="12px"
        bg="gray.50"
        color="gray.700"
        _hover={{ bg: "gray.100", cursor: "pointer", transition: ".25s ease" }}
        onClick={onOpen}
      >
        See More
      </Tag>
    </>
  );
};

const ProfileCard = (props) => {
  const [id, setId] = useState(0);

  const handleNext = () => {
    if (id === props.data.length - 1) {
      setId(0);
    } else {
      setId(id + 1);
    }
  };

  const handleBack = () => {
    if (id === 0) {
      setId(props.data.length - 1);
    } else {
      setId(id - 1);
    }
  };

  const transitions = useTransition(id, {
    from: {
      opacity: 0,
      transform: "translate3d(-5%,0,0)",
      position: "absolute",
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)",
      position: "relative",
    },
    leave: {
      opacity: 0,
      transform: "translate3d(5%,0,0)",
      position: "absolute",
    },
    config: config.stiff,
  });

  return (
    <>
      {transitions((styles, item) => {
        const profile = props.data[item];
        return (
          <AnimatedContainer
            key={item}
            style={styles}
            align="center"
            my={10}
            px={{ base: 5, xl: 10 }}
            mx={{ base: 5, xl: 20 }}
            direction={{ base: "column", lg: "row" }}
          >
            <Flex flexDir="column" align="center">
              <ProfilePhoto
                src={
                  profile["Profile Photo"]
                    ? profile["Profile Photo"][0].url
                    : "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                }
              />
              <Flex my={{ base: 5, xl: 10 }} w="300px" justify="space-around">
                <IconButton
                  onClick={handleBack}
                  variant="unstyled"
                  icon={<BackArrow />}
                />
                <IconButton
                  onClick={handleNext}
                  variant="unstyled"
                  icon={<NextArrow />}
                />
              </Flex>
            </Flex>

            <Flex
              flex={1}
              flexDir="column"
              alignItems={{ base: "center", lg: "flex-start" }}
              ml={4}
            >
              <NameHeading>
                {profile["First Name"]} {profile["Last Name"]}
              </NameHeading>
              <Text
                fontSize={{ base: "16px", md: "18px", xl: "20px" }}
                color="gray.light"
                mb={4}
              >
                {profile["Position"]}
              </Text>
              <Flex direction={{ base: "column", lg: "row" }}>
                <Flex flex={1} flexDir="column" mr={{base: 0, xl: 10}} mb={5}>
                  <ProfileHeading>About Me</ProfileHeading>
                  <div style={{ maxHeight: "300px" }}>
                    <Flex maxHeight="300px">
                      <Flex
                        sx={{
                          ".swiper-container": {
                            height: "auto",
                            paddingRight: 5,
                          },
                          ".swiper-slide": {
                            height: "auto",
                          },
                        }}
                      >
                        <Swiper
                          direction={"vertical"}
                          slidesPerView={"auto"}
                          freeMode={true}
                          scrollbar={true}
                          mousewheel={true}
                        >
                          <SwiperSlide>{profile["About Me"]}</SwiperSlide>
                        </Swiper>
                      </Flex>
                    </Flex>
                  </div>
                </Flex>
                <Flex
                  flex={1}
                  flexDir={{ base: "column", md: "row", lg: "column" }}
                  alignItems="flex-start"
                >
                  <Flex flexDir="column" flex={{ base: 1, xl: "auto" }} mb={5}>
                    <ProfileHeading>At a Glance</ProfileHeading>
                    <HStack alignItems="flex-start" mb={2}>
                      <LocationIcon />
                      <Text color="gray.light">{profile.Location}</Text>
                    </HStack>
                    <HStack alignItems="flex-start">
                      <WorkIcon />
                      <Text color="gray.light">
                        {profile["Work Experience"]}
                      </Text>
                    </HStack>
                  </Flex>
                  <Flex
                    flexDir="column"
                    flex={{ base: 1, xl: "auto" }}
                    mb={5}
                    px={{ md: 5, lg: 0 }}
                  >
                    <ProfileHeading>Industry Knowledge</ProfileHeading>
                    <Wrap spacing={1} flexDir="column" maxHeight="100px">
                      <Tags
                        tags={profile["Industry Expertise"]}
                        bg="blue.lighter"
                        color="blue.darker"
                        modalColor="blue.light"
                        modalText={`${profile["First Name"].replace(
                          / /g,
                          ""
                        )}'s Industry Expertise`}
                      />
                    </Wrap>
                  </Flex>
                  <Flex flexDir="column" flex={{ base: 1, xl: "auto" }} mb={5}>
                    <ProfileHeading>Sustainability Services</ProfileHeading>
                    <Wrap spacing={1} flexDir="column" maxHeight="100px">
                      <Tags
                        tags={profile["Sustainability Service Expertise"]}
                        bg="yellow.lighter"
                        color="yellow.darker"
                        modalColor="yellow.accent"
                        modalText={`${profile["First Name"].replace(
                          / /g,
                          ""
                        )}'s Sustainability Industries`}
                      />
                    </Wrap>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </AnimatedContainer>
        );
      })}
    </>
  );
};

export default ProfileCard;
