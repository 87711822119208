import React from "react";
import { Tooltip } from "antd";
import { AiFillQuestionCircle } from "react-icons/ai";

const QuestionCircle = ({title}) => {
  return (
    <>
      <Tooltip title={title} placement="right">
        <AiFillQuestionCircle className="question-circle" size="16px" />
      </Tooltip>
    </>
  );
};

export default QuestionCircle;
