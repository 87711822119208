import React from "react";

import { Divider, Text } from "@chakra-ui/react";
import { Progress, Form, Select } from "antd";
import { FormLabel, FormHeading, SubHeading } from "../General/Styled";
import COLORS from "../../colors";
import { CLIMATE_INDUSTRIES, GENERAL_INDUSTRIES } from "../General/constants";
import ButtonGroup from "../General/ButtonGroup";

//Router and Redux
import {
  updateFormSubsection,
  updateCustomizedSubsection,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const { Option, OptGroup } = Select;

const SIZE_OPTIONS = ["1-10", "11-50", "51-200", "200-1000", "1000+"];

const CTechCompanyTypePage = (props) => {
  const formState = useSelector((state) => state.form.climateTech);
  const dispatch = useDispatch();

  const nextPage = () => {
    props.history.push("/4");
  };

  return (
    <>
      <Progress
        percent={40}
        showInfo={false}
        strokeColor={COLORS.blue.dark}
        strokeWidth="10px"
      />
      <FormHeading>Company Details</FormHeading>
      <SubHeading>
        Great to have you here. The following questions will help us better
        tailor our services for your{" "}
        <Text as="span" fontWeight="semibold">
          Climate Tech Company
        </Text>
        .
      </SubHeading>
      <Divider width="150px" mb="4" />
      <Form
        onFinish={(val) => {
          console.log(val);
          dispatch(updateFormSubsection("climateTech", val));
          dispatch(updateCustomizedSubsection("climateTech", "companyType"));
          nextPage();
        }}
      >
        <FormLabel>My Industry is</FormLabel>
        <Form.Item
          name="companyDomain"
          id="companyDomain"
          rules={[
            {
              required: true,
              message: "Please input your company domain",
            },
          ]}
          style={{ marginBottom: "1em" }}
          initialValue={formState.companyDomain && formState.companyDomain}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Company Domain"
            size="large"
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <OptGroup label="Climate Tech">
              {CLIMATE_INDUSTRIES.map((domain) => (
                <Option value={domain}>{domain}</Option>
              ))}
            </OptGroup>
            <OptGroup label="General">
              {GENERAL_INDUSTRIES.map((domain) => (
                <Option value={domain}>{domain}</Option>
              ))}
            </OptGroup>
          </Select>
        </Form.Item>
        <FormLabel>Company Size</FormLabel>
        <Form.Item
          name="companySize"
          id="companySize"
          rules={[
            {
              required: true,
              message: "Please input your company size",
            },
          ]}
          initialValue={formState.companySize && formState.companySize}
        >
          <Select placeholder="Company Size" size="large">
            {SIZE_OPTIONS.map((companySize) => (
              <Option value={companySize}>{companySize}</Option>
            ))}
          </Select>
        </Form.Item>
        <ButtonGroup backLink="/2" />
      </Form>
    </>
  );
};

export default CTechCompanyTypePage;
