const formReducer = (
  state = { companyType: "", climateTech: {}, fund: {}, other: {} },
  action
) => {
  switch (action.type) {
    case "SET_COMPANY_TYPE":
      // If the current chosen type is the same, then keep the data, otherwise...
      // Wipe all records except for the first two pages so that overlapping fields don't affect each other
      return action.data === state.companyType
        ? { ...state, companyType: action.data }
        : {
            name: state.name,
            companyName: state.companyName,
            website: state.website,
            companyType: action.data,
            climateTech: {},
            fund: {},
            other: {},
          };
    case "UPDATE_FORM_STANDARD":
      return { ...state, ...action.data };
    case "UPDATE_FORM_SUBSECTION":
      return {
        ...state,
        [action.subsection]: { ...state[action.subsection], ...action.data },
      };
    default:
      return state;
  }
};

export default formReducer;
