import React from "react";
import { Flex } from "@chakra-ui/react";
import { CTechProjectTypePage } from "../ClimateTech";
import { FundFocusPage } from "../Fund";
import { OtherProjectSpecsPage } from "../Other";
import { FormContainer } from "../General/Styled";
import Sidebar from "../General/Sidebar";
import LogoContainer from "../General/LogoContainer";

//Redux and Router
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const FormPageFourContainer = (props) => {
  const companyType = useSelector((state) => state.form.companyType);

  //Checks if the companyType page has been visited
  const isViewable = useSelector(state => state.formCompletion);

  if (!isViewable.climateTech.companyType && !isViewable.fund.fundType && !isViewable.other.companyType) {
    return <Redirect to="/1" />
  }

  return (
    <Flex>
      <FormContainer>
        <Flex
          width={{ md: "60%", base: "80%" }}
          flexDirection="column"
          justifySelf="center"
        >
          <LogoContainer />
          {
            /**
             *  Make sure to pass {...props} to all components so that they can access
             *  route navigation
             */
            companyType === "climateTech" ? (
              <CTechProjectTypePage {...props} />
            ) : companyType === "fund" ? (
              <FundFocusPage {...props} />
            ) : (
              <OtherProjectSpecsPage {...props} />
            )
          }
        </Flex>
      </FormContainer>
      <Sidebar />
    </Flex>
  );
};

export default FormPageFourContainer;
