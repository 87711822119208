import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useTransition, animated, config } from "@react-spring/web";
import TitleSlide from "./TitleSlide";
import CalendlySlide from "./CalendlySlide";
import CarouselSlide from "./CarouselSlide";
import ProfileSlide from "./ProfileSlide";
import TimelineSlide from "./TimelineSlide";
import { Profiles } from "../General/constants";

const AnimatedFlex = animated(Flex);

const ResultsPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const formData = useSelector((state) => state.form);
  const expertNeeds = formData.expertNeeds || [];
  const industries =
    formData.climateTech.companyDomain ||
    formData.fund.investmentAreas ||
    formData.other.companyDomain ||
    [];

  let airtableData = {};

  if (formData.companyType === "climateTech") {

    let techData = formData.climateTech;

    airtableData = {
      Name: formData.name,
      "Company Name": formData.companyName,
      Email: formData.email,
      "Expert Needs": formData.expertNeeds,
      "Company Type": formData.companyType,
      "Company Domain": techData.companyDomain,
      "Company Size": techData.companySize,
      "Project Type": techData.projectType,
      "Project Length": techData.projectLength,
      "Product Type": techData.productType,
      "Project Needs": techData.projectNeed,
      "Goals": techData.goal
    }
  } else if (formData.companyType === "fund") {

    let fundData = formData.fund;

    airtableData = {
      Name: formData.name,
      "Company Name": formData.companyName,
      Email: formData.email,
      "Expert Needs": formData.expertNeeds,
      "Company Type": formData.companyType,
      "Fund Focus": fundData.fundFocus,
      "Fund Goals": fundData.fundGoals,
      "Fund Type": fundData.fundType,
      "Funded Climate Companies": fundData.hasFundedClimate,
      "Investment Areas": fundData.investmentAreas
    }

  } else if (formData.companyType === "other") {
    let other = formData.other;

    airtableData = {
      Name: formData.name,
      "Company Name": formData.companyName,
      Email: formData.email,
      "Expert Needs": formData.expertNeeds,
      "Company Type": formData.companyType,
      "Company Domain": other.companyDomain,
      "Company Size": other.companySize,
      "Project Type": other.projectType,
      "Project Length": other.projectLength,
      "Product Type": other.productType,
      "Goals": other.goal
    }
  }

  const transitions = useTransition(!isLoading, {
    from: {
      opacity: 0,
      transform: "translateY(50px)",
    },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0 },
    config: config.default,
  });

  const calculateScore = (expert) => {
    let needsIntersect = expertNeeds.filter((need) =>
      expert.fields["Sustainability Expertise"].includes(need)
    );
    let industryIntersect = industries.filter((industry) =>
      expert.fields["Industry Expertise"].includes(industry)
    );

    return 0.6 * needsIntersect.length + 0.4 * industryIntersect.length;
  };

  useEffect(() => {
    axios
      .get(
        "https://api.airtable.com/v0/appTH7mpHktrM8iWN/Expert%20Onboarding%20Form",
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`,
          },
        }
      )
      .then(async (res) => {
        let data = res.data;

        let expertProfiles = Profiles.map((expert) => expert);

        let maxScore = 0;
        let expert3 = data.records[0].fields;

        data.records.forEach((expert) => {
          let currScore = calculateScore(expert);
          if (currScore >= maxScore) {
            maxScore = currScore;
            expert3 = expert.fields;
          }
        });

        expertProfiles.push(expert3);

        setData(expertProfiles);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(
        "https://api.airtable.com/v0/appTH7mpHktrM8iWN/Business%20Funnel%20Responses",
        {
          fields: airtableData,
          typecast: true,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // handle success
        console.log(response);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }, []);

  if (isLoading) {
    return (
      <Flex
        bg="gray.background"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Spinner
          color="blue.light"
          size="xl"
          mb="10"
          thickness="3px"
          speed="0.5s"
        />
        <Text color="blue.light" fontSize="18px">
          Personalizing Your Results...
        </Text>
      </Flex>
    );
  }

  return (
    <>
      {transitions(
        (styles, item) =>
          item && (
            <AnimatedFlex
              bg="gray.background"
              direction="column"
              pb="10"
              style={styles}
            >
              <TitleSlide />
              <ProfileSlide data={data} />
              <CarouselSlide />
              <TimelineSlide />
              <CalendlySlide />
            </AnimatedFlex>
          )
      )}
    </>
  );
};

export default ResultsPage;
