import React from "react";

// Components
import { Flex } from "@chakra-ui/react";
import { SectionHeading, SectionSubheading } from "../General/Styled";

const Heading = (props) => {
  return (
    <>
      <Flex w={{ base: "90%", md: "85%", lg: "75%" }} alignItems="center">
        <Flex
          display={{ base: "none", md: "flex" }}
          bg="yellow.accent"
          flex="2"
          h="4px"
        />
        <Flex
          flex={{ base: "100%", md: "5", lg: "auto" }}
          flexDir="column"
          justifyContent="center"
          px="6"
        >
          <Flex
            display={{ base: "flex", md: "none" }}
            alignSelf="flex-start"
            bg="yellow.accent"
            width="20%"
            h="3px"
            mb="6"
          />
          <SectionHeading>
            {props.title}
          </SectionHeading>
        </Flex>
        <Flex
          display={{ base: "none", md: "flex" }}
          bg="yellow.accent"
          flex="2"
          h="4px"
        />
      </Flex>

      <Flex justifyContent="center" mt="2" px="6" w="100%">
        <SectionSubheading w={{ base: "90%", md: "450px" }}>
          {props.description}
        </SectionSubheading>
      </Flex>
    </>
  );
};

export default Heading;
