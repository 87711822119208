import React from "react";
import styled from "styled-components";

import { Box, Flex, Text } from "@chakra-ui/react";

import { BsArrowDown } from "react-icons/bs";
import { ReactComponent as TimelineOne } from "../../assets/TimelineOne.svg";
import { ReactComponent as TimelineTwo } from "../../assets/TimelineTwo.svg";
import { ReactComponent as TimelineThree } from "../../assets/TimelineThree.svg";
import { ReactComponent as TimelineFour } from "../../assets/TimelineFour.svg";
import { ReactComponent as TimelineFive } from "../../assets/TimelineFive.svg";

const Teardrop = styled(Box).attrs((props) => ({
  width: "200px",
  height: "200px",
  borderRadius: "0 50% 50% 50%",
  border: "3px solid",
  borderColor: "blue.light",
  transform: "rotate(225deg)",
}))``;

const TeardropReversed = styled(Box).attrs((props) => ({
  width: "200px",
  height: "200px",
  borderRadius: "0 50% 50% 50%",
  border: "3px solid",
  borderColor: "blue.light",
  transform: "rotate(45deg)",
}))``;

const Dot = styled(Box).attrs((props) => ({
  position: "absolute",
  bgColor: "blue.light",
  borderRadius: "50px",
  width: "15px",
  height: "15px",
}))``;

const TimelineHoverable = (props) => {
  return (
    <>
      <Flex
        width="70%"
        minWidth="900px"
        flexDir="column"
        display={{ base: "none", lg: "flex" }}
      >
        <TimelineTop />

        <TimelineBar />

        <TimelineBottom />
      </Flex>
      <Flex width="85%" flexDir="column" display={{ base: "flex", lg: "none" }}>
        <TimelineFlex
          illustration={<TimelineOne />}
          title="1: First Call"
          description="Let us get to know a little more about you and your needs."
        />
        <TimelineFlex
          illustration={<TimelineTwo />}
          title="2: Scoping"
          description="We’ll define the project timeline and set things in stone."
        />
        <TimelineFlex
          illustration={<TimelineThree />}
          title="3: Set Up "
          description="We’ll get you set up in our virtual office and hit the ground running."
        />
        <TimelineFlex
          illustration={<TimelineFour />}
          title="4: Check In"
          description="We check in often to make sure we’re meeting our targets."
        />
        <TimelineFlex
          showArrow={false}
          illustration={<TimelineFive />}
          title="5: Impact "
          description="Celebrate in being one step closer to a greener future."
        />
      </Flex>
    </>
  );
};

const TimelineFlex = ({ illustration, title, description, showArrow = true }) => {
  return (
    <>
      <Flex
        borderRadius="10px"
        px="16"
        py="8"
        bgColor="white"
        boxShadow="0px 0px 10px -4px rgba(0, 0, 0, 0.25)"
        justifyContent="center"
        alignItems="center"
        flexDir={{ base: "column", sm: "row" }}
      >
        <Flex flex="1" justifyContent="center" alignItems="center">
          {illustration}
        </Flex>
        <Flex ml="10" flex="2" flexDir="column">
          <Text fontSize="22px" fontWeight="semibold" fontColor="#3B4045">
            {title}
          </Text>
          <Text fontSize="18px" fontColor="#3B4045">
            {description}
          </Text>
        </Flex>
      </Flex>
      {showArrow && (
        <Flex justifyContent="center" my="3" color="blue.light">
          <BsArrowDown size="28px" />
        </Flex>
      )}
    </>
  );
};

const HoverTeardrop = ({ title, description }) => {
  return (
    <Box className="hover-teardrop" position="absolute">
      <Teardrop bgColor="rgb(255,255,255, .8)" />
      <Flex
        position="absolute"
        top="0"
        py="5"
        px="5"
        height="200px"
        width="200px"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize="20px" fontWeight="semibold">
          {title}
        </Text>
        <Text fontSize="16px" textAlign="center">
          {description}
        </Text>
      </Flex>
    </Box>
  );
};

const ReversedHoverTeardrop = ({ title, description }) => {
  return (
    <Box className="hover-teardrop" position="absolute">
      <TeardropReversed bgColor="rgb(255,255,255, .8)" />
      <Flex
        position="absolute"
        top="0"
        py="5"
        px="5"
        height="200px"
        width="200px"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize="20px" fontWeight="semibold">
          {title}
        </Text>
        <Text fontSize="16px" textAlign="center">
          {description}
        </Text>
      </Flex>
    </Box>
  );
};

const TimelineTop = (props) => {
  return (
    <Flex width="100%" mt="5" position="relative">
      <Flex className="top-teardrop" flex="20%" position="relative">
        <Teardrop>
          <TimelineOne
            style={{ transform: "rotate(-225deg) translateY(-45px)" }}
          />
        </Teardrop>
        <HoverTeardrop
          title="1: First Call"
          description="Let us get to know a little more about you and your needs."
        />
        <Dot top="274px" left="92px" />
      </Flex>
      <Flex
        className="top-teardrop"
        flex="30%"
        justifyContent="flex-end"
        position="relative"
      >
        <Teardrop>
          <TimelineThree
            style={{ transform: "rotate(-225deg) translate(5px, -35px)" }}
          />
        </Teardrop>
        <HoverTeardrop
          title="3: Set Up"
          description="We’ll get you set up in our virtual office and hit the ground running."
        />
        <Dot top="274px" right="92px" />
      </Flex>
      <Flex
        className="top-teardrop"
        flex="50%"
        justifyContent="flex-end"
        position="relative"
      >
        <Teardrop>
          <TimelineFive
            style={{ transform: "rotate(-225deg) translate(2px, -45px)" }}
          />
        </Teardrop>
        <HoverTeardrop
          title="5: Outcome "
          description="Celebrate achieving your sustainability objectives together."
        />
        <Dot top="274px" right="92px" />
      </Flex>
    </Flex>
  );
};

const TimelineBar = (props) => {
  return (
    <>
      <Flex
        width="calc(100% - 184px)"
        position="relative"
        left="92px"
        mt="80px"
      >
        <Box bgColor="blue.light" height="3px" width="100%" />
      </Flex>

      <Flex
        width="calc(100% - 184px)"
        position="relative"
        left="92px"
        mt="10px"
      >
        <Flex width="calc(25% - 46px)" justifyContent="center">
          <Text fontSize="18px" visibility="hidden">WEEK 1</Text>
        </Flex>
        <Flex width="calc(25% - 54px)" justifyContent="center">
          <Text fontSize="18px" visibility="hidden">WEEK 2</Text>
        </Flex>
        <Flex width="calc(25% + 46px)" justifyContent="center">
          <Text fontSize="18px" visibility="hidden">WEEKS 3 - 6</Text>
        </Flex>
        <Flex flex="1" justifyContent="center">
          <Text fontSize="18px" visibility="hidden">WEEKS 7+</Text>
        </Flex>
      </Flex>
    </>
  );
};

const TimelineBottom = (props) => {
  return (
    <Flex width="100%" mt="43px" position="relative">
      <Flex
        className="top-teardrop"
        flex="50%"
        justifyContent="center"
        position="relative"
      >
        <TeardropReversed>
          <TimelineTwo
            style={{ transform: "rotate(-45deg) translateY(45px)" }}
          />
        </TeardropReversed>
        <ReversedHoverTeardrop
          title="2: Scoping"
          description="We’ll define the project timeline, scope and experts you need."
        />
        <Dot top="-89px" right="calc(50% - 8px)" />
      </Flex>
      <Flex
        className="top-teardrop"
        flex="25%"
        justifyContent="flex-end"
        position="relative"
      >
        <TeardropReversed>
          <TimelineFour
            style={{ transform: "rotate(-45deg) translateY(45px)" }}
          />
        </TeardropReversed>
        <ReversedHoverTeardrop
          title="4: Check In"
          description="We'll check in weekly to make sure we’re meeting our targets."
        />
        <Dot top="-89px" right="92px" />
      </Flex>

      {/**
       * This is just for spacing
       */}
      <Flex flex="25%" />
    </Flex>
  );
};

export default TimelineHoverable;
