import React from "react";
import { Flex } from "@chakra-ui/react";
import { CTechCompanyTypePage } from "../ClimateTech";
import { FundTypePage } from "../Fund";
import { OtherCompanyTypePage } from "../Other";
import { FormContainer } from "../General/Styled";
import Sidebar from "../General/Sidebar";
import LogoContainer from "../General/LogoContainer";

//Redux
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const FormPageThreeContainer = (props) => {
  //Determines what route to show
  const companyType = useSelector((state) => state.form.companyType);

  //Checks if the companyType page has been visited
  const isViewable = useSelector((state) => state.formCompletion.companyType);

  if (!isViewable) {
    return <Redirect to="/1" />;
  }

  return (
    <Flex>
      <FormContainer>
        <Flex
          width={{ md: "60%", base: "80%" }}
          flexDirection="column"
          justifySelf="center"
        >
          <LogoContainer />
          {
            /**
             *  Make sure to pass {...props} to all components so that they can access
             *  route navigation
             */
            companyType === "climateTech" ? (
              <CTechCompanyTypePage {...props} />
            ) : companyType === "fund" ? (
              <FundTypePage {...props} />
            ) : (
              <OtherCompanyTypePage {...props} />
            )
          }
        </Flex>
      </FormContainer>
      <Sidebar />
    </Flex>
  );
};

export default FormPageThreeContainer;
