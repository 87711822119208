import { Flex, Divider, Text } from "@chakra-ui/react";
import { Progress, Form, Checkbox } from "antd";
import {
  FormContainer,
  FormLabel,
  FormHeading,
  ButtonNext,
} from "../General/Styled";
import COLORS from "../../colors";
import React from "react";
import Sidebar from "../General/Sidebar";
import LogoContainer from "../General/LogoContainer";
import QuestionCircle from "../General/QuestionCircle";
import _ from "lodash";

//Router and Redux
import { updateFormStandard, updateStandardSection } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";

const EXPERTS_OPTIONS = {
  "Sustainability Strategy":
    "Strategy Planning, Strategy Workshops, Materiality Assessments, Sustainability Roadmap",
  "Sustainability Operations":
    "Sustainability Reporting, Standards Alignment, Cost Benefit Analysis, Project Management",
  "Sustainability Technical":
    "Life Cycle Assessments, Carbon Measurement & Offsets, Measurement Validations, Green Engineering",
  "Climate Tech Commercialization":
    "Market Entry, Product Management, Customer Acquisition, Business Development",
  "Climate Tech Sourcing & Integration":
    "Solution Landscaping, Sourcing & Selection, Setup & Integration Support, Change Management",
  "Technical Research & Assistance":
    "Due Diligence Support, Research Reports, Landscape Analyses",
  "Expert Insights & Climate Intelligence":
    "Specific Industry Experts, Intelligence Gathering, Expert Sourcing, Short-form Expert Advice",
  "I don’t see the expertise I need": "",
  "I would like help discovering what expertise I need": "",
};

const FormStartPage = (props) => {
  const formState = useSelector((state) => state.form);
  const dispatch = useDispatch();

  const nextPage = () => {
    props.history.push("/2");
  };

  return (
    <Flex>
      <FormContainer
        flex="5"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Flex
          width={{ md: "60%", base: "80%" }}
          flexDirection="column"
          justifySelf="center"
        >
          <LogoContainer />

          <Progress
            percent={10}
            showInfo={false}
            strokeColor={COLORS.blue.dark}
            strokeWidth="10px"
          />

          <FormHeading mb="4" fontSize="48px" fontWeight="normal" color="blue.dark" mt="8">
            Getting Started
          </FormHeading>
          <Divider width="150px" mb="4" />

          <Form
            onFinish={(val) => {
              console.log(val);
              dispatch(updateFormStandard(val));
              dispatch(updateStandardSection("expertNeeds"));
              nextPage();
            }}
          >
            <FormLabel>What experts would you like to source?</FormLabel>
            <Form.Item
              name="expertNeeds"
              id="expertNeeds"
              rules={[
                {
                  required: true,
                  message: "Please input your expert needs",
                },
              ]}
              initialValue={formState.expertNeeds && formState.expertNeeds}
              style={{ marginBottom: "1em" }}
            >
              <Checkbox.Group>
                {_.map(EXPERTS_OPTIONS, (val, key) => (
                  <Flex mb="3">
                    <Checkbox value={key}>
                      <Text fontSize="16px" ml="2">
                        {key} <QuestionCircle title={val} />
                      </Text>
                    </Checkbox>
                  </Flex>
                ))}
              </Checkbox.Group>
            </Form.Item>

            <Flex alignSelf="center" justifyContent="flex-end">
              <ButtonNext>Next</ButtonNext>
            </Flex>
          </Form>
        </Flex>
      </FormContainer>
      <Sidebar />
    </Flex>
  );
};

export default FormStartPage;
