import React from "react";
import { Image, Circle, Flex } from "@chakra-ui/react";

const ProfilePhoto = (props) => {
  return (
    <>
      <Flex flexDir="column" px={{base: 5, md: 10, xl: 20}}>
        <Circle
          position="absolute"
          transform="translate(-50px, -10px)"
          zIndex="0"
          w="120px"
          h="120px"
          bg="blue.lighter"
          display={{base: "none", xl: "flex"}}
        />
        <Image
          w="260px"
          h="340px"
          borderRadius="130px"
          src={props.src}
          fallbackSrc="https://singularity-ffc.s3.amazonaws.com/products/55816/images/55816_4c8faeaa-0e71-4e1e-9843-e9734cebd285.png"
          fit="cover"
          zIndex="1"
        />
        <Circle
          position="absolute"
          transform="translate(170px, 220px)"
          zIndex="0"
          w="140px"
          h="140px"
          bg="yellow.lighter"
          display={{base: "none", xl: "flex"}}
        />
      </Flex>
    </>
  );
};

export default ProfilePhoto;
