import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//Routes
import {
  FormStartPage,
  FormCompanyTypePage,
  FormConfirmationPage,
  FormPageThreeContainer,
  FormPageFourContainer,
  FormPageFiveContainer,
  FormPageSixContainer
} from "./components/Form";
import { ResultsPage } from "./components/Results";

//Styles
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./Theme";
import "antd/dist/antd.css";
import "./Sass/App.scss";


function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Switch>
          <Route
            path="/1"
            exact
            render={(props) => <FormStartPage {...props} />}
          />
          <Route
            path="/2"
            exact
            render={(props) => <FormCompanyTypePage {...props} />}
          />
          <Route
            path="/3"
            exact
            render={(props) => <FormPageThreeContainer {...props} />}
          />
          <Route
            path="/4"
            exact
            render={(props) => <FormPageFourContainer {...props} />}
          />
          <Route
            path="/5"
            exact
            render={(props) => <FormPageFiveContainer {...props} />}
          />
          <Route
            path="/6"
            exact
            render={(props) => <FormPageSixContainer {...props} />}
          />
          <Route
            path="/confirm"
            exact
            render={(props) => <FormConfirmationPage {...props} />}
          />
          <Route
            path="/results"
            exact
            render={(props) => <ResultsPage {...props} />}
          />
          <Route path="/*" render={(props) => <Redirect to="/1" />} />
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;
