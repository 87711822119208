import React from "react";
import { Icon } from "@chakra-ui/react";

const WorkIcon = (props) => {
  return (
    <Icon
      width="5"
      height="5"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7241 5.3291H14.2241V3.5791C14.2241 2.60785 13.4454 1.8291 12.4741 1.8291H8.97412C8.00287 1.8291 7.22412 2.60785 7.22412 3.5791V5.3291H3.72412C2.75287 5.3291 1.98287 6.10785 1.98287 7.0791L1.97412 16.7041C1.97412 17.6753 2.75287 18.4541 3.72412 18.4541H17.7241C18.6954 18.4541 19.4741 17.6753 19.4741 16.7041V7.0791C19.4741 6.10785 18.6954 5.3291 17.7241 5.3291ZM12.4741 5.3291H8.97412V3.5791H12.4741V5.3291Z"
        fill="#656D75"
      />
    </Icon>
  );
};

export default WorkIcon;
