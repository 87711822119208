import { Flex, Center, Wrap } from "@chakra-ui/react";
import { SidebarHeading } from "./Styled";
import COLORS from "../../colors";
import React from "react";
import { ReactComponent as IconKPMG } from "../../assets/iconKPMG.svg";
import { ReactComponent as IconYale } from "../../assets/iconYale.svg";
import { ReactComponent as IconAccenture } from "../../assets/iconAccenture.svg";
import { ReactComponent as IconOxford } from "../../assets/iconOxford.svg";
import { ReactComponent as IconWEF } from "../../assets/iconWEF.svg";
import { ReactComponent as IconPWC } from "../../assets/iconPWC.svg";
import { ReactComponent as IconBerkeley } from "../../assets/iconBerkeley.svg";

const Sidebar = (props) => {

  return (
      <Flex flex="2" display={{base: "none", lg:"flex"}} color="white" flexDirection="column" justifySelf="end" bgColor="blue.light">
        <Center flexDirection="column" bg={COLORS.blue.light} minHeight="100vh" pb="min(15px)">
        <SidebarHeading mt="min(10px)">EXPERTS FROM</SidebarHeading>
          <Wrap width="90%" spacing="30px" justify="center"> 
            <IconYale/>
            <IconKPMG/>           
            <IconAccenture/>         
            <IconOxford/>
            <IconWEF/>
            <IconPWC/>
            <IconBerkeley/>
          </Wrap>
        </Center>
      </Flex>
  );
};

export default Sidebar;