const defaultState = {
  expertNeeds: false,
  companyType: false,
  climateTech: {
    companyType: false,
    projectType: false,
    projectSpecs: false,
    goals: false,
  },
  fund: { focus: false, goals: false, fundType: false },
  other: {
    companyType: false,
    projectSpecs: false,
    serviceInterests: false,
    goals: false,
  },
  contact: false,
};

const formCompletionReducer = (state = defaultState, action) => {
  switch (action.type) {
    /**
     * Standard sections exist regardless of which part
     * of which companyType you select. As such, they are plain
     * booleans, and it is easier to update them with their own
     * function.
     */
    case "UPDATE_STANDARD_SECTION":
      return { ...state, [action.updateType]: true };
    /**
     * Customized sections are based off the company type you choose.
     * As such, their state is tracked in a subobject.
     */
    case "UPDATE_CUSTOMIZED_SUBSECTION":
      const oldState = state[action.updateType];
      //returns the state with the subsection updated
      return {
        ...state,
        [action.updateType]: { ...oldState, [action.updateSubsection]: true },
      };
    default:
      return state;
  }
};

export default formCompletionReducer;
