// For ANTD when you can't use the theme provider from Chakra

const COLORS = {
  gray: {
    50: "#F1F2F4",
    100: "#D7DBE0",
    200: "#BDC4CB",
    300: "#A4ADB7",
    400: "#8A96A3",
    500: "#707F8F",
    600: "#5A6672",
    700: "#434C56",
    800: "#2D3339",
    900: "#16191D",
    dark: "#3B4045",
  },
  blue: {
    50: "#EBF2F9",
    100: "#C8DBEF",
    200: "#A5C3E4",
    300: "#81ACD9",
    400: "#5E94CF",
    500: "#3B7DC4",
    600: "#2F649D",
    700: "#234B76",
    800: "#18324E",
    900: "#0C1927",
    light: "#689BD2",
    dark: "#0F539E",
    selection: "#1890ff",
  },
  purple: {
    50: "#F2EBF9",
    100: "#DCC8EF",
    200: "#C5A4E4",
    300: "#AE81DA",
    400: "#975DD0",
    500: "#813AC5",
    600: "#672E9E",
    700: "#4D2376",
    800: "#33174F",
    900: "#1A0C27",
  },
  pink: {
    50: "#F9EBF2",
    100: "#EFC8DB",
    200: "#E5A4C3",
    300: "#DA81AC",
    400: "#D05D95",
    500: "#C53A7D",
    600: "#9E2E64",
    700: "#76234B",
    800: "#4F1732",
    900: "#270C19",
  },
  cyan: {
    50: "#ECF8F8",
    100: "#CBEBEC",
    200: "#AADEDF",
    300: "#88D1D3",
    400: "#67C4C6",
    500: "#45B7BA",
    600: "#379395",
    700: "#2A6E6F",
    800: "#1C494A",
    900: "#0E2525",
  },
  teal: {
    50: "#ECF8F2",
    100: "#CBECDC",
    200: "#A9E0C5",
    300: "#87D4AF",
    400: "#66C798",
    500: "#44BB82",
    600: "#369668",
    700: "#29704E",
    800: "#1B4B34",
    900: "#0E251A",
  },
  green: {
    50: "#ECF8EC",
    100: "#CAECCB",
    200: "#A8E0A9",
    300: "#86D488",
    400: "#64C866",
    500: "#42BD45",
    600: "#359737",
    700: "#287129",
    800: "#1B4B1C",
    900: "#0D260E",
  },
  yellow: {
    accent: "#EBAC03",
  },
  red: {
    50: "#FCE9E9",
    100: "#F7C0C0",
    200: "#F19898",
    300: "#EC6F6F",
    400: "#E64747",
    500: "#E11E1E",
    600: "#B41818",
    700: "#871212",
    800: "#5A0C0C",
    900: "#2D0606",
    accent: "#f5222d"
  },
  orange: {
    50: "#FCF0E9",
    100: "#F6D6C1",
    200: "#F0BB99",
    300: "#EAA170",
    400: "#E58648",
    500: "#DF6C20",
    600: "#B2561A",
    700: "#864113",
    800: "#592B0D",
    900: "#2D1606",
  },
};

export default COLORS;
